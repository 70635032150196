import {
  StatsV2,
  EStatsV2Theme,
  EContainerVariant,
  Container,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TStatsV2ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'stats-v2' }
>;

const StatsV2Connected: React.FC<TStatsV2ConnectedProps> = props => {
  const { stats, theme = [] } = props;

  return (
    <Container variant={EContainerVariant.Full}>
      <StatsV2 stats={stats || []} theme={theme as EStatsV2Theme} />
    </Container>
  );
};

export default StatsV2Connected;
