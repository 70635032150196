import { cva } from 'cva';
import React from 'react';

import { EStatsV2Theme, EStatsV2Variant } from './constants';

type TStat = { title?: string; subTitle?: string; description?: string };

const wrapperCva = cva('', {
  variants: {
    variant: {
      [EStatsV2Variant.Default]:
        'grid grid-cols-[repeat(auto-fit,minmax(127px,1fr))] flex-wrap gap-6 tablet:flex tablet:justify-center',
      [EStatsV2Variant.Liquidity]:
        'grid grid-cols-[repeat(auto-fit,minmax(127px,1fr))] tablet:grid-cols-[repeat(auto-fit,minmax(168px,1fr))] flex-wrap gap-6 desktop:flex desktop:justify-center',
    },
  },
});

const titleCva = cva('[overflow-wrap:anywhere]', {
  variants: {
    theme: {
      [EStatsV2Theme.StatsV2Theme1]: 'text-interface-1000',
      [EStatsV2Theme.StatsV2Theme2]: 'text-interface-50',
    },
    variant: {
      [EStatsV2Variant.Default]:
        'text-7xl font-bold leading-snug tablet:text-8xl tablet:leading-relaxed desktop:text-10xl desktop:leading-normal',
      [EStatsV2Variant.Liquidity]: 'text-8xl font-normal leading-[60px]',
    },
  },
});

const subTitleCva = cva(
  'text-xl font-semibold leading-extra-loose tablet:text-2xl',
  {
    variants: {
      theme: {
        [EStatsV2Theme.StatsV2Theme1]: 'text-interface-1000',
        [EStatsV2Theme.StatsV2Theme2]: 'text-interface-50',
      },
    },
  },
);

const descriptionCva = cva('', {
  variants: {
    theme: {
      [EStatsV2Theme.StatsV2Theme1]: '',
      [EStatsV2Theme.StatsV2Theme2]: 'text-interface-50',
    },
    variant: {
      [EStatsV2Variant.Default]:
        'text-sm font-medium leading-loose tablet:text-center',
      [EStatsV2Variant.Liquidity]: 'text-sm font-medium leading-[18.90px]',
    },
  },
  compoundVariants: [
    {
      theme: EStatsV2Theme.StatsV2Theme1,
      variant: EStatsV2Variant.Default,
      className: 'text-interface-800',
    },
    {
      theme: EStatsV2Theme.StatsV2Theme1,
      variant: EStatsV2Variant.Liquidity,
      className: 'text-interface-700',
    },
  ],
});

const cardWrapperCva = cva(
  'flex flex-1 flex-col gap-2 tablet:min-w-[168px] tablet:max-w-[260px] tablet:basis-[calc(20%-24px)] desktop:min-w-[166px] desktop:max-w-[320px] overflow-hidden',
  {
    variants: {
      variant: {
        [EStatsV2Variant.Default]: 'tablet:items-center',
        [EStatsV2Variant.Liquidity]: '',
      },
    },
  },
);

const titleWrapperCva = cva('flex flex-col', {
  variants: {
    variant: {
      [EStatsV2Variant.Default]: 'tablet:items-center tablet:text-center',
      [EStatsV2Variant.Liquidity]: '',
    },
  },
});

export type TStatsV2Props = {
  stats: TStat[];
  theme?: EStatsV2Theme;
  variant?: EStatsV2Variant;
};

export const StatsV2: React.FC<TStatsV2Props> = props => {
  const {
    stats,
    theme = EStatsV2Theme.StatsV2Theme1,
    variant = EStatsV2Variant.Default,
  } = props;

  return (
    <div className={wrapperCva({ variant })}>
      {stats.map((stat, index) => (
        <div key={index} className={cardWrapperCva({ variant })}>
          <div className={titleWrapperCva({ variant })}>
            <div className={titleCva({ theme, variant })}>{stat.title}</div>
            <div className={subTitleCva({ theme })}>{stat.subTitle}</div>
          </div>

          <div className={descriptionCva({ theme, variant })}>
            {stat.description}
          </div>
        </div>
      ))}
    </div>
  );
};
